html,
body,
#root {
  height: 100%;
  margin: 0 auto;
  min-width: 360px;
}

body {
  font-family: Roboto, sans-serif !important;
  margin: 0;
}

h1 {
  font-family: Roboto, sans-serif;
  font-style: inherit;
  font-weight: 900;
  line-height: 75px;
  font-size: 60px;
  letter-spacing: 0.01em;
  color: #fafafa;
}

h3 {
  font-family: Roboto, sans-serif;
  font-style: inherit;
  font-weight: 400;
  line-height: 66px;
  font-size: 40px;
  color: #080910;
}

h4 {
  font-family: Roboto, sans-serif;
  font-style: inherit;
  font-weight: normal;
  line-height: normal;
  font-size: 24px;
}

h5 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  font-size: 17px;
  letter-spacing: 0.01em;
}
textarea:-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

textarea::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

::-webkit-input-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

::-moz-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

:-ms-input-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

:-moz-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

::-webkit-scrollbar-track {
  border-radius: 14px;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-color: #989fa6;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
button:focus {
  outline: none;
}

:focus {
  outline: none;
}

@media screen and (max-width: 740px) {
  h3 {
    line-height: 40px;
    font-size: 28px;
  }
  h4 {
    font-size: 18px;
  }
  .fc-toolbar.fc-header-toolbar {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .fc-right {
    margin-top: 20px;
  }
}

body > #zmmtg-root {
  display: none;
}

input[type='text']:focus,
input[type='text'] {
  outline: none !important;
  transition: none !important;
}

.audio-option-menu .dropup .btn-group .btn-default {
  color: #333 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
  overflow: scroll;
  max-height: 100px;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
  word-wrap: break-word;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

