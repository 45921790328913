html,
body,
#root {
  height: 100%;
  margin: 0 auto;
  min-width: 360px;
}

body {
  font-family: Roboto, sans-serif !important;
  margin: 0;
}

h1 {
  font-family: Roboto, sans-serif;
  font-style: inherit;
  font-weight: 900;
  line-height: 75px;
  font-size: 60px;
  letter-spacing: 0.01em;
  color: #fafafa;
}

h3 {
  font-family: Roboto, sans-serif;
  font-style: inherit;
  font-weight: 400;
  line-height: 66px;
  font-size: 40px;
  color: #080910;
}

h4 {
  font-family: Roboto, sans-serif;
  font-style: inherit;
  font-weight: normal;
  line-height: normal;
  font-size: 24px;
}

h5 {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  font-size: 17px;
  letter-spacing: 0.01em;
}
textarea:-moz-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

textarea::-webkit-input-placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

::-webkit-input-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

::-moz-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

:-ms-input-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

:-moz-placeholder {
  font-size: 15px;
  letter-spacing: 0.01em;
  color: #989fa6;
}

::-webkit-scrollbar-track {
  border-radius: 14px;
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-color: #989fa6;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
button:focus {
  outline: none;
}

:focus {
  outline: none;
}

@media screen and (max-width: 740px) {
  h3 {
    line-height: 40px;
    font-size: 28px;
  }
  h4 {
    font-size: 18px;
  }
  .fc-toolbar.fc-header-toolbar {
    flex-direction: column;
  }
  .fc-right {
    margin-top: 20px;
  }
}

body > #zmmtg-root {
  display: none;
}

input[type='text']:focus,
input[type='text'] {
  outline: none !important;
  transition: none !important;
}

.audio-option-menu .dropup .btn-group .btn-default {
  color: #333 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
